import React from "react";
import Emoji from "./Emoji";

function SearchMovies({ getUserSearchQuery, closeMovieDetails }) {
	return (
		<section className="search-container">
			<h1 className="logo" onClick={closeMovieDetails}>
				Litterboxd <Emoji symbol="😺" label="Grinning Cat" />
			</h1>
			<img src="/svgs/search.svg" alt="search" className="search-icon" onClick={getUserSearchQuery} />
			<input type="text" name="searchMovie" placeholder="Search Movies..." onKeyPress={getUserSearchQuery} />
		</section>
	);
}

export default SearchMovies;
